<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <router-link to="/cbtexam" class="text-white pl-3">
            <i class="ni ni-bold-right"></i> CBT Exam</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> School Subject</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <card header-classes="bg-transparent">
            <div class="row">
                <div class=" col-md-6">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="form-horizontal">
                                <form role="form" @submit.prevent="submitdata">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input v-model="ExamSubjectval" class="form-control">
                                    </div>

                                    <div class="text-center mb-3">
                                        <button type="submit" class="btn btn-primary my-4">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-6">
                    <div class="form-inline">
                        <div class=" input-group">
                            <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search...">
                            <div class=" input-group-append">
                                <button type="button" @click.prevent="getsearch" class="btn btn-secondary"><i class="fa fa-search"></i> Search </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Subject</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in subjectitems" :key="item.Id">
                                    <td>{{ item.ExamSubject }}</td>
                                    <td><button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)"><i class="fa fa-edit"></i> Edit</button>
                                        <button class="btn btn-secondary btn-sm" v-on:click="openquestion(item.Id)"><i class="ni ni-ruler-pencil"></i> Questions</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </card>
    </div>

</div>
</template>

<script>
import swal from 'sweetalert'
export default {

    name: 'cbtsubject',
    data() {
        return {
            Idval: 1,
            ExamIdval: localStorage.getItem('ExamId') || '',
            ExamSubjectval: '',
            searchval: '',
            subjectitems: '',
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        newterm: function () {
            this.ExamSubjectval = '';
            this.process = 'N';
        },
        openquestion: function (Id) {
            localStorage.setItem('ExamsubjectId', Id);
            this.$router.push('/cbtquestion');
        },
        edititem: function (Id) {
            this.onloader = 'flex'
            let ExamId = this.ExamIdval;
            this.$store.dispatch('getcbtexamsubjectbyid', {
                    Id,
                    ExamId
                })
                .then(resp => {
                    this.Idval = Id;
                    this.ExamSubjectval = resp.ExamSubject;
                    this.process = 'U';
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                   this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let ExamId = this.ExamIdval;
            let ExamSubject = this.ExamSubjectval;
            let Id = this.Idval;
            let processop = this.process;
            let username = this.$store.state.auth.username;
            let accesskey = this.$store.state.auth.accesskey;
            this.onloader = 'flex';
            let payload = {
                Id,
                ExamId,
                ExamSubject,
                processop,
                username,
                accesskey
            }
            this.$store.dispatch('processcbtexamsubject', payload).then((resp) => {
                    this.subjectitems = resp;
                    this.ExamSubjectval = "";
                    this.process = 'N';
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                   this.checkresponse(err.response.data);
                })
        },
        getcbtexamsubject: function () {
            this.onloader = 'flex'
            let id = this.ExamIdval;
            this.$store.dispatch('getcbtexamsubject', id)
                .then((resp) => {
                    this.subjectitems = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                   this.checkresponse(err.response.data);
                })
        },
        getsearch: function () {

            let Id = this.ExamIdval;
            let item = this.searchval;
            let userdata = {
                Id,
                item
            }
            this.$store.dispatch('getcbtexamsubjectsearch', userdata)
                .then((resp) => {
                    this.subjectitems = resp;

                })
                .catch(err => {
                   this.checkresponse(err.response.data);

                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id == 6 || this.$store.state.auth.user_status_id == 7 || this.$store.state.auth.user_status_id == 9) {
            this.getcbtexamsubject();
        } else {

            this.$store.dispatch('logout')
            this.$router.push('/login')
        }
       
    }
};
</script>

<style></style>
