<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/school/registrar" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Registrar
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Alumni</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="form-inline text-center">
            <select v-model="SessionIdval" class="form-control" @change="getalumnibysession()">
              <option value="0">Select Session</option>
              <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                {{ item.SchSession }}
              </option>
            </select>
            <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search..." />
            <div class="input-group-append">
              <button type="button" @click.prevent="getsearch" class="btn btn-secondary">
                <i class="fa fa-search"></i> Search
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <td>Admission Number</td>
                  <td>Student Name</td>
                  <td>Session</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in alumnis" :key="item.Id">
                  <td>{{ item.AdmNo }}</td>
                  <td>{{ item.StudentName }}</td>
                  <td>{{ item.SchSession }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "classattendance",
  data() {
    return {
      accessDetails:null,
      SessionIdval: 0,
      searchval: "",
      Sessionitems: null,
      alumnis: null,
      onloader: "none",
    };
  },

  methods: {
    getalumnibysession: function () {
      let id = this.SessionIdval;
      let searchitem = null;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        id,
        searchitem,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getalumnibysession", userdata)
        .then((resp) => {
          this.alumnis = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      let id = this.SessionIdval;
      let searchitem = this.searchval;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        id,
        searchitem,
        username,
        accesskey,
      };

      this.$store
        .dispatch("getalumnisearch", userdata)
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregsession: function () {

      this.Sessionitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },

    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (this.accessDetails.isSchoolStaff == false) {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    } else {
      this.getregsession();
      this.getclassinclassassigned();
    }
  },
};
</script>

<style></style>
