<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"><i class="ni ni-bold-right"></i> CBT Exam</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body bg-translucent-secondary">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group my-2">
                      <img
                        v-if="uploadedimage"
                        height="100"
                        :src="uploadedimage"
                      />
                      <img v-else height="100" src="img/brand/empty.png" />
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        v-on:change="handleFileUpload()"
                      />
                      <br />
                      <button
                        class="btn btn-outline-success"
                        @click.prevent="submitFile()"
                      >
                        <i class="ni ni-cloud-upload-96"></i>Upload
                      </button>
                    </div>
                    <div class="form-group">
                      <label>Exam Name</label>
                      <input v-model="ExamNameval" class="form-control" />
                    </div>

                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class="input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="col-lg-4" v-for="item in examitems" :key="item.Id">
                  <div class="card bg-translucent-success shrink my-3">
                    <div class="card-body text-center">
                      <img
                        v-if="item.ExamLogo"
                        :src="
                          $store.state.auth.baseurl + item.ExamLogo.substr(1)
                        "
                        class="card-img-top"
                        alt=""
                      />
                      <img
                        v-else
                        src="img/brand/empty.png"
                        class="card-img-top"
                        alt=""
                      />
                      <h5 class="text-white mt-2">{{ item.ExamName }}</h5>
                      <div class="card-footer">
                        <button
                          class="btn btn-secondary btn-sm my-2"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                        <button
                          class="btn btn-default btn-sm"
                          v-on:click="opensubject(item.Id)"
                        >
                          <i class="ni ni-collection"></i> Exam Subject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "class",
  data() {
    return {
      Idval: 1,
      ExamNameval: "",
      ExamLogoval: "",
      examitems: "",
      file: "",
      uploadedimage: "",
      searchval: "",
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    newterm: function () {
      this.ExamNameval = "";
      this.process = "N";
    },
    opensubject: function (Id) {
      localStorage.setItem("ExamId", Id);
      this.$router.push("/cbtexamsubject");
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getcbtexamid", Id)
        .then((resp) => {
          this.Idval = Id;
          this.ExamNameval = resp.ExamName;
          this.ExamLogoval = resp.ExamLogo;
          if (resp.ExamLogo != null) {
            this.uploadedimage =
              this.$store.state.auth.baseurl + resp.ExamLogo.substr(1);
          }
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let ExamName = this.ExamNameval;
      let ExamLogo = this.ExamLogoval;
      let Id = this.Idval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        ExamName,
        ExamLogo,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("processcbtexam", payload)
        .then((resp) => {
          this.examitems = resp;
          this.ExamNameval = "";
          this.ExamLogoval = null;
          this.uploadedimage = null;
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getcbtexam: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getcbtexam")
        .then((resp) => {
          this.examitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.onloader = "none";
          this.$router.push("/login");
        });
    },
    getsearch: function () {
      this.$store
        .dispatch("getcbtexamsearch", this.searchval)
        .then((resp) => {
          this.examitems = resp;
        })
        .catch((err) => {
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.Passportval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadadminfile", form)
        .then((resp) => {
          this.ExamLogoval = resp;
          this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1);
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      this.$store.state.auth.user_status_id == 6 ||
      this.$store.state.auth.user_status_id == 7 ||
      this.$store.state.auth.user_status_id == 9
    ) {
      this.getcbtexam();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style></style>
