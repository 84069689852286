<template>
    <div v-if="accessDetails">
        <div class=" card bg-transparent">
            <div class="card-body">
                <div class="row">
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isBursal == true">
                        <router-link to="/school/student-registration">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Students</h5>
                                </div>
                            </div>
                        </router-link>
                    </div>                    
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isBursal == true">
                        <router-link to="/school/staff-registration">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Staffs</h5>
                                </div>
                            </div>
                        </router-link>
                    </div>                        
                </div>
            </div>
        </div>

    </div>
</template> 

<script>
export default {
  data() {
    return {    
      accessDetails: null,
      staffDetails: null,
      onloader: "none",
    };
  },
  mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        this.staffDetails = this.$store.getters['SchoolStaff/_schoolStaff'];
    },
};
</script>