<template>
    <div>
      <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 text-white">
            <!-- Card stats -->
             <router-link to="/dashboard" class="text-white pl-3">
               Dashboard                        
            </router-link><i class="fa fa-ellipsis-v px-2"></i><span> Exam</span>   
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
           <card type="default" header-classes="bg-transparent">                        
                <Submenu></Submenu>  
            </card>
        </div>

    </div>
</template>
<script> 
  import Submenu from './Submenu.vue';
  export default {
    components: {
     Submenu
    },  
    data() {        
      return {       
        accessDetails: null,
      };
    },
    mounted(){      
      this.accessDetails = this.$store.getters['auth/_user'];
      if (this.accessDetails.isSchoolStudent == false) {
           if(this.accessDetails.SchoolId == null){
            this.$store.dispatch('auth/Logout')
            this.$router.push('/login')
           }
        }
    }   
  };
</script>
<style></style>
