<template>
    <div v-if="staffDetails && accessDetails">
        <div class=" card bg-transparent">
            <div class="card-body">
                <div class="row">
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-class-attendance">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted text-white mb-0">Class Attendance
                                    </h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xs-3 m-2 shrink"
                        v-if="staffDetails.isSubjectTeacher == true || staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-mark-record">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted text-white mb-0">Mark Record</h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-conduct-record">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Conduct Assessment 
                                    </h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-psycomotor-skill-record">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Psycomotor Skill Assessment
                                       </h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xs-3 m-2 shrink" v-if="staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-physical-development-assessment">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Physical Development Assessment
                                       </h5>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xs-3 m-2 shrink"
                        v-if="staffDetails.isSubjectTeacher == true || staffDetails.isClassTeacher == true">
                        <router-link to="/school/student-mock-record">
                            <div class="card bg-gradient-success">
                                <div class="card-body text-center">
                                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow mb-2">
                                        <i class="ni ni-folder-17"></i>
                                    </div>
                                    <h5 class="card-title text-muted mb-0 text-white">Mock Record</h5>
                                </div>
                            </div>
                        </router-link>
                    </div>                                      
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {    
      accessDetails: null,
      staffDetails: null,
      onloader: "none",
    };
  },
  mounted() {
        this.accessDetails = this.$store.getters['auth/_user'];
        this.staffDetails = this.$store.getters['SchoolStaff/_schoolStaff'];
    },
};
</script>