<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/school" class="text-white">
        <i class="ni ni-badge"></i> School
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i>Staff Registration
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Biodata</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 pb-2">
                          <img
                            v-if="uploadedimage"
                            height="100"
                            :src="uploadedimage"
                          />
                          <img v-else height="100" src="img/brand/empty.png" />
                          <input
                            type="file"
                            id="file"
                            ref="file"
                            v-on:change="handleFileUpload()"
                          />
                          <br />
                          <button
                            class="btn btn-outline-secondary"
                            @click.prevent="submitFile()"
                          >
                            <i class="ni ni-cloud-upload-96"></i>Upload
                          </button>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Surname</label>
                            <input v-model="LastNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">First Name</label>
                            <input
                              v-model="FirstNameval"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Other Name</label>
                            <input
                              v-model="MiddleNameval"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Email Address</label>
                            <input
                              type="email"
                              v-model="EmailAddressval"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Phpne No.</label>
                            <input
                              type="tel"
                              v-model="PhoneNoval"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Sex</label>
                            <select v-model="SexIdval" class="form-control">
                              <option value="0"></option>
                              <option value="10">Male</option>
                              <option value="11">Female</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Marital Status</label>
                            <select
                              v-model="MaritalStatusIdval"
                              class="form-control"
                            >
                              <option value="0"></option>
                              <option value="12">Married</option>
                              <option value="13">Single</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Date of Birth</label>
                            <input
                              type="date"
                              v-model="DOBval"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Address</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">State</label>
                            <select
                              v-model="StateIdval"
                              class="form-control"
                              @change="getlga($event)"
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in stateitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.StateName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Local Government</label>
                            <select v-model="LGAIdval" class="form-control">
                              <option value="0"></option>
                              <option
                                v-for="item in lgaitems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.Local_Govt_Name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">City</label>
                            <base-input v-model="Cityval"></base-input>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Address</label>
                            <textarea
                              v-model="Addressval"
                              class="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Operation</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Service Number</label>
                            <base-input v-model="ServiceNoval"></base-input>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Staff Type</label>
                            <select
                              class="form-control"
                              v-model="CategoryIdval"
                            >
                              <option value="0"></option>
                              <option value="14">Teaching</option>
                              <option value="15">Non-Teaching</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center mb-3">
                    <button
                      type="button"
                      @click.stop="closeform()"
                      class="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div slot="header" class="row align-items-center">
            <h6 class="text-uppercase text-muted ls-1 mb-1">
              Registered Staff
            </h6>
          </div>
          <div class="form-inline">
            <button
              type="button"
              @click.prevent="newterm"
              class="btn btn-default"
            >
              <i class="ni ni-fat-add"></i>Add New
            </button>
            <div class="input-group">
              <input
                v-model="searchval"
                class="form-control"
                @keyup="getsearch"
                placeholder="Search..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click.prevent="getsearch"
                  class="btn btn-secondary"
                >
                  <i class="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2 row">
            <div class="col-md-3" v-for="item in staffs" :key="item.Id">
              <div class="card bg-translucent-success shrink my-3">
                <div class="card-body text-center">
                  <img
                    v-if="item.Passport"
                    :src="$store.state.auth.baseurl + item.Passport.substr(1)"
                    class="rounded-circle"
                    alt=""
                    height="60"
                  />
                  <img
                    v-else
                    src="img/brand/loguser.png"
                    class="rounded-circle"
                    alt=""
                    height="60"
                  />
                  <h5 class="text-white mt-3">{{ item.SeviceNo }}</h5>
                  <h6 class="text-white">{{ item.StaffName }}</h6>
                  <h6 class="text-white">{{ item.EmailAddress }}</h6>
                  <h6 class="text-white">{{ item.PhoneNo }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "staff",
  data() {
    return {
      dataid: 1,
      accessDetails: null,
      OrgIdval: localStorage.getItem("SchoolId") || "",
      Passportval: "",
      ContactIdval: 1,
      FirstNameval: "",
      MiddleNameval: "",
      LastNameval: "",
      PhoneNoval: "",
      EmailAddressval: "",
      SexIdval: "",
      DOBval: "",
      UserIdval: 1,
      StateIdval: "",
      LGAIdval: "",
      Cityval: "",
      AddressIdval: 1,
      Addressval: "",
      ServiceNoval: "",
      CategoryIdval: null,
      MaritalStatusIdval: null,
      lgaitems: [],
      staffs: null,
      file: "",
      uploadedimage: "",
      searchval: "",
      process: "N",
      openform: false,
      inputdisable: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      stateitems: (state) => state.addressinfo.stateitems,
    }),
  },
  methods: {
    newterm: function () {
      this.Passportval = "";
      this.StateIdval = "";
      this.LGAIdval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.Cityval = "";
      this.Addressval = "";
      this.ServiceNoval = "";
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.Passportval = "";
      this.StateIdval = "";
      this.LGAIdval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.Cityval = "";
      this.Addressval = "";
      this.ServiceNoval = "";
      this.CategoryIdval = null;
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffbyid", Id)
        .then((resp) => {
          this.dataid = resp.Id;
          if (resp.Passport != null) {
            this.uploadedimage =
              this.$store.state.auth.baseurl + resp.Passport;
          }
          this.Passportval = resp.Passport;
          this.ContactIdval = resp.ContactId;
          this.IsPrincipalval = resp.IsPrincipal;
          this.IsBursalval = resp.IsBursal;
          this.IsPortalAdminval = resp.IsPortalAdmin;
          this.IsHostelMasterval = resp.IsHostelMaster;
          this.IsLibraryAdminval = resp.IsLibraryAdmin;
          this.IsCounselorval = resp.IsCounselor;
          this.IsRegistrarval = resp.IsRegistrar;
          this.IsHODval = resp.IsHOD;
          this.StateIdval = resp.StateId;
          this.downloadlga(this.StateIdval);
          this.LGAIdval = resp.LGId;
          this.FirstNameval = resp.FirstName;
          this.Cityval = resp.City;
          this.AddressIdval = resp.AddId;
          this.Addressval = resp.AddressOne;
          this.ServiceNoval = resp.SeviceNo;
          this.CategoryIdval = resp.CategoryId;
          this.MiddleNameval = resp.MiddleName;
          this.LastNameval = resp.LastName;
          this.EmailAddressval = resp.EmailAddress;
          this.PhoneNoval = resp.PhoneNo;
          this.SexIdval = resp.SexId;
          this.MaritalStatusIdval = resp.MaritalStatusId;
          this.UserIdval = resp.UserId;
          if (resp.DateofBirth != null || resp.DateofBirth != "") {
            this.DOBval = this.frontEndDateFormat(resp.DateofBirth);
          }

          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },

    submitdata: function () {
      let Id = this.dataid;
      let Passport = this.Passportval;
      let ContactId = this.ContactIdval;
      let IsPrincipal = this.IsPrincipalval;
      let IsBursal = this.IsBursalval;
      let IsPortalAdmin = this.IsPortalAdminval;
      let IsHostelMaster = this.IsHostelMasterval;
      let IsLibraryAdmin = this.IsLibraryAdminval;
      let IsCounselor = this.IsCounselorval;
      let IsRegistrar = this.IsRegistrarval;
      let IsHOD = this.IsHODval;
      let StateId = this.StateIdval;
      let LGAId = this.LGAIdval;
      let FirstName = this.FirstNameval;
      let City = this.Cityval;
      let AddressId = this.AddressIdval;
      let Address = this.Addressval;
      let ServiceNo = this.ServiceNoval;
      let CategoryId = this.CategoryIdval;
      let MiddleName = this.MiddleNameval;
      let LastName = this.LastNameval;
      let EmailAddress = this.EmailAddressval;
      let MaritalStatusId = this.MaritalStatusIdval;
      let PhoneNo = this.PhoneNoval;
      let SexId = this.SexIdval;
      let DOB = this.DOBval;
      let UserId = this.UserIdval;
      let OrgId = this.OrgIdval;
      let processop = this.process;
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let userdata = {
        Id,
        UserId,
        Passport,
        ContactId,
        MaritalStatusId,
        CategoryId,
        ServiceNo,
        IsPrincipal,
        IsBursal,
        IsPortalAdmin,
        IsHostelMaster,
        IsLibraryAdmin,
        IsCounselor,
        IsRegistrar,
        IsHOD,
        StateId,
        LGAId,
        FirstName,
        City,
        AddressId,
        Address,
        MiddleName,
        LastName,
        EmailAddress,
        PhoneNo,
        SexId,
        DOB,
        OrgId,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("processschoolstaff", userdata)
        .then((resp) => {
          this.staffs = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          if (
            err.response.data === "Access Expired" ||
            err.response.data === "Invalid Credential"
          ) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          }
        });
    },
    getstaffs: function () {
      let OrgId = this.OrgIdval;
      if (OrgId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getschoolstaff", OrgId)
          .then((resp) => {
            this.staffs = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/home");
          });
      } else {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    getsearch: function () {
      let OrgId = this.OrgIdval;
      let item = this.searchval;
      let userdata = {
        OrgId,
        item,
      };
      this.$store
        .dispatch("getschoolstaffssearch", userdata)
        .then((resp) => {
          this.staffs = resp;
        })
        .catch((err) => {
          this.checkresponse(err);
        });
    },

    getlga: function () {
      this.onloader = "flex";
      this.lgaitems = [];
      let StateId = this.StateIdval;
      this.$store
        .dispatch("getlgas", StateId)
        .then((resp) => {
          this.lgaitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err);
        });
    },

    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.username;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.Passportval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadadminfile", form)
        .then((resp) => {
          this.Passportval = resp;
          this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1);
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err);
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.accessDetails = this.$store.getters['auth/_user'];
    if (
      this.accessDetails.isSuperAdmin == true ||
      this.accessDetails.isAdmin == true ||
      this.accessDetails.isSupportAdmin == true ||
      this.accessDetails.isAffliateMarketer == true
    ) {
      this.getstaffs();
    } else {
      this.$store.dispatch('auth/Logout')
      this.$router.push('/login')
    }   
  },
};
</script>

<style></style>
